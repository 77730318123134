import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    connect() {
        //if (!window.sessionStorage.getItem('informacjaOCookies')) {
        if (this.getCookie('informacjaOCookies') !== '1') {
            this.element.classList.remove('d-none');
        } else {
            let maxAge = 24 * 60 * 60;
            document.cookie = 'informacjaOCookies=1;max-age='+maxAge+';path=/';
        }
    }
    close(event) {
        event.preventDefault();
        //window.sessionStorage.setItem('informacjaOCookies', 1);
        let maxAge = 15 * 60;
        document.cookie = 'informacjaOCookies=1;max-age='+maxAge+';path=/';
        this.element.classList.add('d-none');
    }

    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}
