import { Controller } from '@hotwired/stimulus';
import { Alert } from 'mdb-ui-kit';

export default class extends Controller {
    connect() {

        const alertContainer = document.getElementById('alert-container');
        if (this.element.parentNode !== alertContainer) {
            //alertContainer.appendChild(this.element);

            return;
        }

        const alert = new Alert(this.element);
        alert.show();
    }
}
