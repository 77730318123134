import { Controller } from '@hotwired/stimulus';
import { Modal } from 'mdb-ui-kit';
import jQuery from 'jquery';
import { useDispatch } from 'stimulus-use';
import * as mdb from "mdb-ui-kit";

export default class extends Controller {
    static targets = ['modal', 'modalBody'];
    static values = {
        formUrl: String,
    }
    modal = null;

    connect() {
        useDispatch(this);
    }

    async openModal(event) {
        this.modalBodyTarget.innerHTML = 'Loading...';
        this.modal = new Modal(this.modalTarget);
        this.modal.show();

        this.modalBodyTarget.innerHTML = await jQuery.ajax(this.formUrlValue);

        this.initMdb();
    }

    async submitForm(event) {
        event.preventDefault();
        const $form = jQuery(this.modalBodyTarget).find('form');

        try {
            await jQuery.ajax({
                url: this.formUrlValue,
                method: $form.prop('method'),
                data: $form.serialize(),
            });
            this.modal.hide();
            this.dispatch('success');
        } catch (e) {
            this.modalBodyTarget.innerHTML = e.responseText;
            this.initMdb();
        }
    }

    modalHidden() {
        this.modal.hide();
    }

    initMdb() {
        document.querySelectorAll('.form-outline').forEach((formOutline) => {
            mdb.Input.getOrCreateInstance(formOutline);
        });

        document.querySelectorAll('.select').forEach((formOutline) => {
            mdb.Select.getOrCreateInstance(formOutline);
        });

        document.querySelectorAll('.mdb-datepicker').forEach((formOutline) => {
            mdb.Datepicker.getOrCreateInstance(formOutline, {
                format: 'yyyy-mm-dd',
                inline: true,
                toggle: true,
                startDay: 1,
                monthsFull: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
                monthsShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis','Gru'],
                weekdaysFull: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
                weekdaysShort: ['Nie', 'Pon', 'Wto', 'Śro', 'Czw', 'Pią', 'Sob'],
                weekdaysNarrow: ['N', 'P', 'W', 'Ś', 'C', 'P', 'S'],
            });
        });

        document.querySelectorAll('.mdb-timepicker').forEach((formOutline) => {
            mdb.Timepicker.getOrCreateInstance(formOutline, {
                format24: true,
                inline: true
            });
        });
    }
}
