import { Controller } from '@hotwired/stimulus';
import { Modal } from 'mdb-ui-kit';
import jQuery from 'jquery';
import { useDispatch } from 'stimulus-use';

export default class extends Controller {

    static values = {
        formUrl: String,
        title: String,
        content: String,
    }
    modal = null;

    connect() {
        useDispatch(this);
    }

    async openModal(event) {
        if (this.modal === null) {
            jQuery('#schowekModalLabel').html(this.titleValue);
            jQuery('#schowekModalBody').html(this.contentValue);
            this.modal = new Modal(jQuery('#schowekModal'));
        }
        this.modal.show();

        //jQuery('#loginModalBody').html(await jQuery.ajax(this.formUrlValue));
    }

    async submitForm(event) {
        event.preventDefault();
        const $form = jQuery('#schowekModalBody').find('form');

        try {
            await jQuery.ajax({
                url: this.formUrlValue,
                method: $form.prop('method'),
                data: $form.serialize(),
            });
            this.modal.hide();
            this.dispatch('success');
        } catch (e) {
            jQuery('#schowekModalBody').innerHTML = e.responseText;
        }
    }

    modalHidden() {
        this.modal.hide();
    }
}
