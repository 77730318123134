import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    connect() {
        this.initializeScriptTag(document, 'script', 'inpost-geowidget-js');
        this.initializeLinkTag(document, 'link', 'inpost-geowidget-css');
    }

    initializeScriptTag (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (!d.getElementById(id)) {
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://geowidget.inpost.pl/inpost-geowidget.js';
            fjs.parentNode.insertBefore(js, fjs);
        }
    }

    initializeLinkTag (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (!d.getElementById(id)) {
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://geowidget.inpost.pl/inpost-geowidget.css';
            fjs.parentNode.insertBefore(js, fjs);
        }
    }
}
