import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static targets = ['button', 'content'];

    static values = {class: String, index: String}

    connect() {
        this.toggle();
    }

    toggle(event) {
        if (event) {
            this.indexValue = event.target.dataset.index;
        }

        this.contentTargets.forEach((element, i) => {
            if(element.dataset.index.split(' ').includes(this.indexValue)) {
                element.classList.remove(this.classValue)
            } else {
                element.classList.add(this.classValue)
            }
        })
    }
}
