import { Controller } from '@hotwired/stimulus';
import Swal from 'sweetalert2';
import axios from "axios";
import jQuery from "jquery";


export default class extends Controller {

    static targets = ['modal'];
    static values = {
        url: String,
    };

    openModal(event) {
        event.preventDefault();

        Swal.fire({
            title: 'Zarchiwizować adres?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#125ca5',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Tak, zarchiwizuj!',
            cancelButtonText: 'Anuluj',
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .post(this.urlValue, {})
                    .then(response => {
                        if (response.data.error) {
                            Swal.fire(
                                'Nie udało się!',
                                response.data.error,
                                'warning',
                            ).then(() => {
                                window.location.reload();
                            })
                        } else {
                            Swal.fire(
                                'Udało się!',
                                'Adres został zarchiwizowany.',
                                'success',
                            ).then(() => {
                                window.location.reload();
                            })
                        }

                    })
                    .catch(error => {
                        Swal.fire(
                            'Błąd!',
                            'Nie udało się zarchiwizować adresu.',
                            'error',
                        )
                    })
                    .finally(() => {
                    })
            }
        })
    }
}
