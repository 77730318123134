import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static targets = ['brutto', 'netto', 'quantity'];
    connect() {
        this.broadcastChannel = new BroadcastChannel('basket');

        let $controller = this;
        this.broadcastChannel.onmessage = function (event){
            let data = event.data;
            if ($controller.hasBruttoTarget) {
                $controller.bruttoTarget.innerText = Math.ceil(data['wartosc_razem_b']*100)/100;
            }
            if ($controller.hasNettoTarget) {
                $controller.nettoTarget.innerText = Math.ceil(data['wartosc_razem_n']*100)/100;
            }
            if ($controller.hasQuantityTarget) {
                $controller.quantityTarget.innerText = data['liczba_jednostek'];
            }

            window.sessionStorage.setItem('basket_price', data['wartosc_razem_n'])
            window.sessionStorage.setItem('basket_price_inc', data['wartosc_razem_b'])
            window.sessionStorage.setItem('basket_quantity', data['liczba_jednostek'])
        };
    }

    addProduct(event)
    {

    }

    removeProduct(event)
    {
    }

    render(event)
    {

    }
}
