import {Controller} from '@hotwired/stimulus';
import {useWindowResize} from 'stimulus-use';
import jQuery from "jquery";

import "jquery.easing";
import "skitter-slider";

export default class extends Controller {
    connect() {
        useWindowResize(this)

        var options = {
            dots: false,
            numbers: true,
            numbers_align: 'right',
            responsive: {
                small: {
                    animation: 'fade',
                    max_width: 768,
                    suffix: ''
                },
                medium: {
                    animation: 'directionRight',
                    max_width: 1024,
                    suffix: ''
                }
            }
        };

        /*
        if (document.location.search) {
            var array = document.location.search.split('=');
            var param = array[0].replace('?', '');
            var value = array[1];

            if (param === 'animation') {
                options.animation = value;
            } else if (param === 'type_navigation') {
                if (value === 'dots_preview') {
                    $('.border_box').css({'marginBottom': '40px'});
                    options['dots'] = true;
                    options['preview'] = true;
                } else {
                    options[value] = true;
                    if (value === 'dots') $('.border_box').css({'marginBottom': '40px'});
                }
            }
        }
         */

        jQuery(this.element).skitter(options);

        let height = jQuery('.info_slide').height() + 17;

        jQuery('.info_slide').css({
            'top': 'unset',
            'bottom': '-'+height+'px',
            'right': '0px',
        });
        jQuery('.skitter').css({
            'max-width': 'initial'
        });

    }

    async windowResize({ width, height, event }) {
        await this.sleep(300);
        let height2 = jQuery('.info_slide').height() + 12;
        jQuery('.info_slide').css({
            'top': 'unset',
            'bottom': '-'+height2+'px',
            'right': '0px',
        });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
