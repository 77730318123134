import { Controller } from '@hotwired/stimulus';
import jQuery from 'jquery';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static values = {
        picked: Number,
    }
    static targets = ['button', 'options'];

    connect() {
        /*
        this.optionsTargets.forEach((option, index) => {
            option.addEventListener('click', (el) => {
                this.pick(el);
            });
        });
        */
    }

    changePick(event) {
        this.buttonTarget.classList.add('d-none');
        this.optionsTargets.forEach((option, index) => {
            option.classList.remove('d-none');
        });

    }

    pick(event) {
        this.buttonTarget.classList.remove('d-none');
        this.optionsTargets.forEach((option, index) => {
            if (option !== event.currentTarget) {
                option.classList.add('d-none');
            }
        });

        if (event.target.tagName !== 'INPUT') {
            jQuery(event.currentTarget).find('input').click();
        }
    }
}
